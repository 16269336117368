// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-africas-voices-js": () => import("./../../../src/pages/africas-voices.js" /* webpackChunkName: "component---src-pages-africas-voices-js" */),
  "component---src-pages-assembled-js": () => import("./../../../src/pages/assembled.js" /* webpackChunkName: "component---src-pages-assembled-js" */),
  "component---src-pages-augmented-reality-js": () => import("./../../../src/pages/augmented-reality.js" /* webpackChunkName: "component---src-pages-augmented-reality-js" */),
  "component---src-pages-autodesk-dynamo-js": () => import("./../../../src/pages/autodesk-dynamo.js" /* webpackChunkName: "component---src-pages-autodesk-dynamo-js" */),
  "component---src-pages-blockpunk-js": () => import("./../../../src/pages/blockpunk.js" /* webpackChunkName: "component---src-pages-blockpunk-js" */),
  "component---src-pages-constructiviq-js": () => import("./../../../src/pages/constructiviq.js" /* webpackChunkName: "component---src-pages-constructiviq-js" */),
  "component---src-pages-contextually-js": () => import("./../../../src/pages/contextually.js" /* webpackChunkName: "component---src-pages-contextually-js" */),
  "component---src-pages-dearpost-js": () => import("./../../../src/pages/dearpost.js" /* webpackChunkName: "component---src-pages-dearpost-js" */),
  "component---src-pages-hackathons-js": () => import("./../../../src/pages/hackathons.js" /* webpackChunkName: "component---src-pages-hackathons-js" */),
  "component---src-pages-horseroof-js": () => import("./../../../src/pages/horseroof.js" /* webpackChunkName: "component---src-pages-horseroof-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-ios-apps-js": () => import("./../../../src/pages/ios-apps.js" /* webpackChunkName: "component---src-pages-ios-apps-js" */),
  "component---src-pages-katikati-js": () => import("./../../../src/pages/katikati.js" /* webpackChunkName: "component---src-pages-katikati-js" */),
  "component---src-pages-klinify-js": () => import("./../../../src/pages/klinify.js" /* webpackChunkName: "component---src-pages-klinify-js" */),
  "component---src-pages-know-app-js": () => import("./../../../src/pages/know-app.js" /* webpackChunkName: "component---src-pages-know-app-js" */),
  "component---src-pages-learnographics-js": () => import("./../../../src/pages/learnographics.js" /* webpackChunkName: "component---src-pages-learnographics-js" */),
  "component---src-pages-logo-design-js": () => import("./../../../src/pages/logo-design.js" /* webpackChunkName: "component---src-pages-logo-design-js" */),
  "component---src-pages-neonlaksa-js": () => import("./../../../src/pages/neonlaksa.js" /* webpackChunkName: "component---src-pages-neonlaksa-js" */),
  "component---src-pages-nimbus-for-work-js": () => import("./../../../src/pages/nimbus-for-work.js" /* webpackChunkName: "component---src-pages-nimbus-for-work-js" */),
  "component---src-pages-nus-grip-js": () => import("./../../../src/pages/nus-grip.js" /* webpackChunkName: "component---src-pages-nus-grip-js" */),
  "component---src-pages-photography-js": () => import("./../../../src/pages/photography.js" /* webpackChunkName: "component---src-pages-photography-js" */),
  "component---src-pages-real-leaders-js": () => import("./../../../src/pages/real-leaders.js" /* webpackChunkName: "component---src-pages-real-leaders-js" */),
  "component---src-pages-resume-js": () => import("./../../../src/pages/resume.js" /* webpackChunkName: "component---src-pages-resume-js" */),
  "component---src-pages-retailkit-js": () => import("./../../../src/pages/retailkit.js" /* webpackChunkName: "component---src-pages-retailkit-js" */),
  "component---src-pages-robin-js": () => import("./../../../src/pages/robin.js" /* webpackChunkName: "component---src-pages-robin-js" */),
  "component---src-pages-semantics-3-js": () => import("./../../../src/pages/semantics3.js" /* webpackChunkName: "component---src-pages-semantics-3-js" */),
  "component---src-pages-sketchbook-js": () => import("./../../../src/pages/sketchbook.js" /* webpackChunkName: "component---src-pages-sketchbook-js" */),
  "component---src-pages-static-websites-js": () => import("./../../../src/pages/static-websites.js" /* webpackChunkName: "component---src-pages-static-websites-js" */)
}

